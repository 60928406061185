export enum EventName {
  ACCORDION_TAB_CLICK = 'Accordion Tab Click',
  FORM_STARTED = 'Form Started',
  FORM_STEP_COMPLETED = 'Form Step Completed',
  FORM_SUBMITTED = 'Form Submitted',
  LQAS_UX_VALIDATION_FLAG_EVALUATED = 'LQAS UX Flag Evaluated',
  LQAS_PAID_FLAG_EVALUATED = 'LQAS Paid Flag Evaluated',
  LQAS_ORGANIC_FLAG_EVALUATED = 'LQAS Organic Flag Evaluated',
  MARKETING_OPT_IN_FLAG_EVALUATED = 'Marketing OptIn Flag Evaluated',
}

// Name you see on the LaunchDarkly dashboard: front facing name
export enum LaunchDarklyFlagName {
  ROLLOUT_LQAS_ORGANIC = 'lqas_test_experiment',
  ROLLOUT_LQAS_PAID = 'lqas_us_paid',
  ROLLOUT_LQAS_UX_VALIDATION = 'Rollout LQAS UX Validation',
  ROLLOUT_MARKETING_OPT_IN = 'Rollout Pricing Form Marketing OptIn',
}

export enum LaunchDarklyFlagValue {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}
